import { FormActions, Actions, LifeApplicationInfo } from './types';
import { moneyToString } from 'utils/helpers';

export const initialState: LifeApplicationInfo = {
  dateOfBirth: null,
  gender: null,
  state: null,
  tobacco: null,
  healthClass: null,
  coverageAmount: null,
  term: null,
  paymentMode: null,
  firstName: null,
  lastName: null,
  email: null,
  notes: null,
  phone: null,
  productType: 'term',
  policySelection: {
    carrierLink: null,
    annualPremium: null,
    carrier: null,
    carrierName: null,
    isTobacco: null,
    issueAge: null,
    issueType: null,
    monthlyPremium: null,
    planName: null,
    planIdentifierName: null,
    productType: null,
    returnOfPremium: null,
    tableRating: null,
    underwritingClass: null,
    useNearestAge: null,
    healthCategory: null,
  },
};

export const formReducer = (state: LifeApplicationInfo, action: FormActions) => {
  switch (action.type) {
    case Actions.UPDATE_CLIENT:
      return {
        ...state,
        ...action.payload,
        coverageAmount: moneyToString(action.payload.coverageAmount),
      };
    case Actions.SELECT_POLICY:
      return {
        ...state,
        policySelection: { ...action.payload },
      };
    case Actions.CLEAR_POLICY:
      return {
        ...state,
        policySelection: initialState.policySelection,
      };
    case Actions.RESET_STATE:
      return {
        ...initialState,
      };
    case Actions.CHANGE_ADVISOR:
      return {
        ...state,
        ...action.payload,
      };
    default:
      return state;
  }
};
