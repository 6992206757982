import { Gender } from 'graph/types';

export interface ContextProps {
  state: LifeApplicationInfo;
  dispatch: React.Dispatch<FormActions>;
}

export enum Actions {
  UPDATE_CLIENT = 'UPDATE_CLIENT',
  SELECT_POLICY = 'SELECT_POLICY',
  CLEAR_POLICY = 'CLEAR_POLICY',
  RESET_STATE = 'RESET_STATE',
  CHANGE_ADVISOR = 'CHANGE_ADVISOR',
}

export type FormActions = UpdateClient | SelectPolicy | ClearPolicy | ResetState | ChangeAdvisor;

interface ResetState {
  type: 'RESET_STATE';
}

interface UpdateClient {
  type: 'UPDATE_CLIENT';
  payload: LifeApplicationInfo;
}

interface SelectPolicy {
  type: 'SELECT_POLICY';
  payload: PolicySelection;
}

interface ClearPolicy {
  type: 'CLEAR_POLICY';
}

interface ChangeAdvisor {
  type: 'CHANGE_ADVISOR';
  payload: LifeApplicationInfo;
}

interface Advisor {
  uuid: string;
  firstName: string;
  lastName: string;
}

interface PolicySelection {
  carrierLink: string | null;
  annualPremium: number | null;
  carrier: string | null;
  carrierName: string | null;
  isTobacco: boolean | null;
  issueAge: number | null;
  issueType: string | null;
  monthlyPremium: number | null;
  planName: string | null;
  planIdentifierName: string | null;
  productType: string | null;
  returnOfPremium: boolean | null;
  tableRating: number | null;
  underwritingClass: string | null;
  useNearestAge: boolean | null;
  healthCategory: string | null;
}

export interface LifeApplicationInfo {
  dateOfBirth: string | null;
  gender: Gender | null;
  state: string | null;
  tobacco: boolean | null;
  healthClass: string | null;
  coverageAmount: string | null;
  term: string | null;
  paymentMode: string | null;
  firstName: string | null;
  lastName: string | null;
  phone: string | null;
  notes: string | null;
  email: string | null;
  policySelection: PolicySelection;
  referringAgent?: Advisor;
  guaranteedAge?: number;
  productType: string;
  income?: number;
  motivation?: string;
  strategy?: string;
  payAgeWhole?: string;
  targetPremiumSPWL?: number;
}
